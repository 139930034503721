import { createRouter } from '@tanstack/react-router';

import { NewPasswordRoute } from '$/routes/Account/NewPasswordRoute';
import { PasswordResetSuccessfulRoute } from '$/routes/Account/PasswordResetSuccessfulRoute';
import { RegistrationSuccessfulPage } from '$/routes/Account/RegistrationSuccessfulPage';
import { CollectionsRoute } from '$/routes/Collections/CollectionsRoute';
import { ColorCollectionsRoute } from '$/routes/Collections/ColorCollectionsRoute';
import { FacadeCollectionsRoute } from '$/routes/Collections/FacadeCollectionsRoute';
import { FloorsCollectionRoute } from '$/routes/Collections/FloorsCollectionRoute';
import { WallsCollectionRoute } from '$/routes/Collections/WallsCollectionRoute';
import { DashboardRoute } from '$/routes/DashboardRoute';
import { ApiDocumentationRoute } from '$/routes/Documentation/ApiDocumentationRoute';
import { TechnicalDocumentationRoute } from '$/routes/Documentation/TechnicalDocumentationRoute';
import { EditorRoute } from '$/routes/Editor/EditorRoute';
import { FavoritesRoute } from '$/routes/FavoritesRoute';
import { CookieDeclarationRoute } from '$/routes/General/CookieDeclarationRoute';
import { LinkExpiredRoute } from '$/routes/General/LinkExpiredRoute';
import { NotAllowedRoute } from '$/routes/General/NotAllowedRoute';
import { InspirationsProjectRoute } from '$/routes/Inspirations/InspirationProjectRoute';
import { InspirationsEditorRoute } from '$/routes/Inspirations/InspirationsEditorRoute';
import { InspirationsRoute } from '$/routes/Inspirations/InspirationsRoute';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { EditorRouteLayout } from '$/routes/Layouts/EditorLayout';
import { ImprintRoute } from '$/routes/Legal/ImprintRoute';
import { IntegrityRoute } from '$/routes/Legal/IntegrityRoute';
import { PrivacyRoute } from '$/routes/Legal/PrivacyRoute';
import { TermsOfServiceRoute } from '$/routes/Legal/TermsOfServieRoute';
import { PricingRoute } from '$/routes/PricingRoute';
import { ProjectsRoute } from '$/routes/ProjectsRoute';
import { RootRoute } from '$/routes/RootRoute';
import { queryClient } from '$/services/fetcher';

export const routeTree = RootRoute.addChildren([
  CookieDeclarationRoute,
  InspirationsEditorRoute,
  InspirationsProjectRoute,
  NotAllowedRoute,
  LinkExpiredRoute,
  NewPasswordRoute,
  RegistrationSuccessfulPage,
  PricingRoute,
  ApiDocumentationRoute,
  TechnicalDocumentationRoute,
  BaseRouteLayout.addChildren([
    DashboardRoute,
    InspirationsRoute,
    ProjectsRoute,
    CollectionsRoute,
    FavoritesRoute,
    ColorCollectionsRoute,
    FloorsCollectionRoute,
    WallsCollectionRoute,
    FacadeCollectionsRoute,
    PasswordResetSuccessfulRoute,
  ]),
  EditorRouteLayout.addChildren([EditorRoute]),
  ImprintRoute,
  IntegrityRoute,
  PrivacyRoute,
  TermsOfServiceRoute,
]);

export const router = createRouter({
  routeTree,
  defaultPreload: false,
  context: { client: queryClient },
});
