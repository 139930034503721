import { HStack, Stack, Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { PositionSection } from '$/pages/EditorPage/components/AttributesSection/components/PropertyOptions/components/PositionSection';
import { ShapeSection } from '$/pages/EditorPage/components/AttributesSection/components/PropertyOptions/components/ShapeSection';
import { SizeSection } from '$/pages/EditorPage/components/AttributesSection/components/PropertyOptions/components/SizeSection';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import {
  Tip,
  TipSection,
} from '$/pages/EditorPage/components/AttributesSection/components/TipSection';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const ColorChecker = () => {
  const { t } = useTranslation();

  const { setColorChecker } = useEditorActions();
  const colorCheckerEnabled = useEditorStore.useColorCheckerEnabled();

  const tips: Tip[] = [
    {
      tipHeader: t('editor.tips.colorCheckerUseCase.title'),
      tipContent: t('editor.tips.colorCheckerUseCase.content'),
    },
  ];

  return (
    <Stack>
      <HStack justify='space-between' px='4' pb='3'>
        <SectionTitle title={'Colorchecker'} icon='color_palette' />
        <Switch
          isChecked={colorCheckerEnabled}
          onChange={(e) => setColorChecker(e.target.checked)}
        />
      </HStack>

      <Stack gap='4' px='4'>
        <TipSection tips={tips} />
        <SizeSection />
        <ShapeSection />
      </Stack>

      <SidebarDivider mt='4' mb='3' />

      <PositionSection />
    </Stack>
  );
};
