import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

export const SizeSection = () => {
  const { t } = useTranslation();

  const { setColorCheckerSettings } = useEditorActions();

  const colorCheckerShape = 0;
  const colorCheckerWidth = 0;
  const colorCheckerHeight = 0;

  return (
    <Stack w='full' mt='3'>
      <SectionTitle title={t('editor.dimensions.title')} icon='size' />
      <EditorNumberInput
        text={t('editor.dimensions.height')}
        initialValue={0}
        onChange={(val) =>
          setColorCheckerSettings(colorCheckerShape, colorCheckerWidth, val)
        }
        minValue={1}
      />
      <EditorNumberInput
        text={t('editor.dimensions.width')}
        initialValue={0}
        onChange={(val) =>
          setColorCheckerSettings(colorCheckerShape, val, colorCheckerHeight)
        }
        minValue={1}
      />
    </Stack>
  );
};
