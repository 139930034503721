import { createRoute } from '@tanstack/react-router';

import { HtmlContentPage } from '$/pages/HtmlContentPage';
import { RootRoute } from '$/routes/RootRoute';
import { legalEndpoints } from '$/services/endpoints';

export const TermsOfServiceRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/tos',
  component: () => (
    <HtmlContentPage contentEndpoint={legalEndpoints.TOS_URL()} />
  ),
});
