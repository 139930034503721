import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Grid,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { VersionCard } from '$/components/core/Authentication/components/VersionCard';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const VersionComparisonModalContent = () => {
  const { t } = useTranslation();
  const onCloseAuthenticationModal = useAuthenticationStore.useOnModalClose();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();

  return (
    <ModalContent
      maxW={{ base: '400px', sm: '875px' }}
      bg='background'
      borderRadius='12px'
    >
      <ModalHeader
        mt={{ base: '4', sm: '8' }}
        ml={{ base: '4', sm: '8' }}
        p='0'
        fontSize={{ base: 'h10', sm: 'h7' }}
      >
        {t('dashboard.authentication.version.label')}
      </ModalHeader>
      <ModalCloseButton
        top='0'
        right='0'
        mt={{ base: '2', sm: '8' }}
        mr={{ base: '4', sm: '8' }}
      />
      <ModalBody
        mt={{ base: '2', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        pb={{ base: '4', sm: '10' }}
      >
        <Grid
          gap={{ base: '4', sm: '6' }}
          templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
        >
          <VersionCard
            variant='default'
            type='free'
            onActionClick={onCloseAuthenticationModal}
          />
          <VersionCard
            variant='primary'
            type='pro'
            onActionClick={() => setAuthenticationModalType('register')}
          />
        </Grid>
      </ModalBody>
    </ModalContent>
  );
};
