import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { User } from '$/services/usecases/authentication/mapper/genericCloudResponse';
import { UserRole } from '$/services/usecases/authentication/mapper/jwt';

export type AuthModalType =
  | 'login'
  | 'register'
  | 'registerOtp'
  | 'passwordForgotten'
  | 'registerSuccess'
  | 'versionComparison'
  | 'loginToSave';

type State = {
  user: User | null;
  userRole: UserRole;
  modalType: AuthModalType;
  isModalOpen: boolean;
  optEmail: string;
  accessToken: string;
  profileImageHash: number;
  refreshFailed: boolean;
};

type Action = {
  authenticate: (user: User, token: string) => void;
  setUser: (user: User) => void;
  setModalType: (type: AuthModalType) => void;
  onModalOpen: (type?: AuthModalType) => void;
  onModalClose: () => void;
  setOtpEmail: (email: string) => void;
  setProfileImageHash: (profileImageHash: number) => void;
};

const initial: State = {
  user: null,
  optEmail: '',
  modalType: 'login',
  isModalOpen: false,
  accessToken: '',
  userRole: 'free',
  profileImageHash: Date.now(),
  refreshFailed: false,
};

export const store = create<State & Action>()(
  persist(
    (set) => ({
      ...initial,
      authenticate: (user, token) => {
        set({ user, accessToken: token, userRole: user.role });
      },
      setUser: (user) => set({ user }),
      setModalType: (modalType) => set({ modalType }),
      onModalClose: () => set({ isModalOpen: false }),
      onModalOpen: (modalType) => {
        set({ isModalOpen: true });
        if (modalType != null) set({ modalType });
      },
      setOtpEmail: (email) => set({ optEmail: email }),
      setProfileImageHash: (profileImageHash) => set({ profileImageHash }),
    }),
    {
      name: 'authentication-store',
      partialize: (s) => ({ refreshFailed: s.refreshFailed }),
    },
  ),
);

export const useAuthenticationStore = createSelectorHooks(store);
