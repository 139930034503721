import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { AttributeHeader } from '$/components/core/Editor/AttributeHeader';
import { trackEvent } from '$/logger';
import { ExportEditorModal } from '$/pages/EditorPage/components/ExportModal';
import { ExportInspirationModal } from '$/pages/InspirationEditorPage/components/ExportModal';

interface Props {
  mode: 'editor' | 'inspiration';
}

export const ExportSection: FC<Props> = ({ mode }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const userRole = useAuthenticationStore.useUserRole();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  const onClick = () => {
    trackEvent(
      'ExportProject',
      'Click',
      mode === 'editor' ? 'Editor' : 'Inspiration',
    );

    if (userRole === 'free') onOpenAuthModal('versionComparison');
    else onOpen();
  };

  return (
    <Stack w='full'>
      <AttributeHeader
        label={t('editor.exportText')}
        icon={<Icon w='18px' icon='upload_file' />}
        description='MISSING TRANSLATION'
      />

      <Button
        h='8'
        fontSize='xs'
        fontWeight='normal'
        onClick={onClick}
        variant='secondary'
      >
        {t('editor.exportButton')}
      </Button>

      {mode === 'inspiration' && (
        <ExportInspirationModal isOpen={isOpen} onClose={onClose} />
      )}
      {mode === 'editor' && (
        <ExportEditorModal isOpen={isOpen} onClose={onClose} />
      )}
    </Stack>
  );
};
