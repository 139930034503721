import {
  Box,
  Button,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { LayerSidebar } from '$/components/core/Editor/LayerSidebar';
import { SaveSection } from '$/components/core/Editor/SaveSection';
import { SidebarDivider } from '$/components/core/Editor/SidebarDivider';
import { useNonFreeAction } from '$/hooks/useNonFreeAction';
import { trackEvent } from '$/logger';
import { ComponentList } from '$/pages/EditorPage/components/ComponentSection/ComponentList';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useSaveEditorProject } from '$/pages/EditorPage/hooks/useSaveEditorProject';
import {
  getLetterFromIndex,
  useEditorStore,
} from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  projectName: string;
}

export const ComponentSection: FC<Props> = ({ projectName }) => {
  const { t } = useTranslation();

  const [isSaving, onSaveProject] = useSaveEditorProject();
  const { createComponent } = useEditorActions();

  const components = useEditorStore.useComponents();

  const shiftAccordionItems = useEditorStore.useShiftAccordionItems();
  const setIsFullscreen = useEditorStore.useSetIsFullscreen();
  const handleNonFreeAction = useNonFreeAction();

  const handleAddElement = () => {
    const componentName = t('editor.component_name', {
      letter: getLetterFromIndex(components.length + 1),
    });
    createComponent(componentName);
    shiftAccordionItems();
  };

  const toggleFullscreen = () => {
    trackEvent('Fullscreen', 'Click', 'Editor');
    handleNonFreeAction(() => setIsFullscreen(true));
  };

  return (
    <LayerSidebar projectName={projectName} pb='2'>
      <HStack
        justify='space-between'
        w='full'
        px='4'
        py='2'
        fontSize='xs'
        fontWeight='bold'
      >
        <Text fontWeight='bold'>{t('editor.componentListHeader')}</Text>
        <IconButton
          minW='0'
          h='auto'
          aria-label={t('editor.addComponentIconAlt')}
          icon={<Icon icon='add_layer' fill='editor.icon' />}
          onClick={handleAddElement}
          variant='text'
        />
      </HStack>

      <Box overflowY='auto' w='full' h='full'>
        <ComponentList />
      </Box>

      <SidebarDivider />

      <SaveSection isSaving={isSaving} onSave={onSaveProject} />

      <Spacer />

      <SidebarDivider />

      <Stack w='full' px='4' py='2'>
        <Button
          w='full'
          h='8'
          fontSize='xs'
          fontWeight='normal'
          leftIcon={<Icon icon='zoom_out_map' width='3' />}
          onClick={toggleFullscreen}
          variant='secondary'
        >
          {t('inspirationEditor.fullscreenMode')}
        </Button>
      </Stack>
    </LayerSidebar>
  );
};
