import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const Links = () => {
  const { t } = useTranslation();

  // const { pathname } = useRouterState().location;

  const { hasPermission } = useAuthorization();

  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  return (
    <Flex align='center'>
      {/*hasPermission('General_Abo_Page') && (
        <GhostButton
          h='full'
          rightIcon={<Icon icon='leaderboard' width='20px' />}
          fontSize='sm'
        >
          <Link to='/pricing'>
            <Box display={{ base: 'none', sm: 'inline-block' }}>
              {t('dashboard.header.subscriptions')}
            </Box>
          </Link>
        </GhostButton>
      )*/}

      {/*(hasPermission('General_Login_Register') ||
        hasPermission('General_User_Profile')) &&
        hasPermission('General_Abo_Page') && (
          <Box
            w='2px'
            h='50%'
            bg={pathname !== '/profile' ? 'header.divider' : 'transparent'}
          />
        )*/}

      {hasPermission('General_Login_Register') && (
        <GhostButton
          onClick={() => onOpenAuthenticationModal('login')}
          rightIcon={<Icon icon='arrow_right' width='13px' />}
          h='full'
          fontSize='sm'
        >
          {t('dashboard.header.signIn')}
        </GhostButton>
      )}
    </Flex>
  );
};
