import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  type: 'free' | 'pro';
  variant: 'default' | 'primary';
  onActionClick: () => void;
}

export const VersionCard: FC<Props> = ({ type, variant, onActionClick }) => {
  const { t } = useTranslation();

  // eslint is lying, it's another type. Recommended by https://www.i18next.com/overview/typescript#example-with-array
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const featureList = t(`dashboard.authentication.version.${type}.features`, {
    returnObjects: true,
  }) as string[];

  return (
    <Stack
      justify='space-between'
      minH={{ base: '320px', sm: '416px' }}
      p={{ base: '4', sm: '8' }}
      color={variant === 'primary' ? 'selectedText' : undefined}
      bg={variant === 'primary' ? 'primaryButton.lightBackground' : undefined}
      border='1px solid'
      borderColor={
        variant === 'primary' ? 'primaryButton.background' : 'border'
      }
      borderRadius='12px'
    >
      <Stack gap='5'>
        <Stack gap='1'>
          <Text fontSize={{ base: 'h10', sm: 'h7' }} fontWeight='bold'>
            {t(`dashboard.authentication.version.${type}.label`)}
          </Text>
          <Text
            color={variant === 'default' ? 'description' : undefined}
            fontSize={{ base: 'sm', sm: 'md' }}
          >
            {t(`dashboard.authentication.version.${type}.description`)}
          </Text>
        </Stack>

        <Stack gap='1' h='max-content'>
          {featureList.map((feature) => (
            <HStack key={feature} align='flex-start'>
              <Icon icon='check_icon' w='14px' pt='1' />
              <Text fontSize={{ base: 'sm', mobile: 'md' }}>{feature}</Text>
            </HStack>
          ))}
        </Stack>
      </Stack>

      <Button
        borderColor={
          type === 'free' ? 'secondaryButton.alternativeBorder' : undefined
        }
        onClick={onActionClick}
        variant={type === 'free' ? 'secondary' : 'primary'}
      >
        {t(`dashboard.authentication.version.${type}.action`)}
      </Button>
    </Stack>
  );
};
