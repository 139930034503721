import { trackEvent } from '$/logger';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const useSaveEditorProject = () => {
  const { saveProject } = useEditorActions();
  const isSaving = useEditorStore.useIsSaving();

  const onSaveProject = () => {
    trackEvent('SaveProject', 'Click', 'Editor');
    useEditorStore.setState({ isSaving: true });
    saveProject();
  };

  return [isSaving, onSaveProject] as const;
};
