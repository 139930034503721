import { jwtDecode } from 'jwt-decode';

import { RegisterData } from '$/components/core/Authentication/components/RegisterForm';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { backendEndpoints } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { GeneralBackendResponseObjectSchema } from '$/services/mapper/authentication';
import { RegistrationResponseSchema } from '$/services/usecases/authentication/mapper/genericCloudResponse';
import { SignInSchema } from '$/services/usecases/authentication/mapper/signin';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { useProfileStore } from '$/stores/useProfileStore';

export type LoginData = {
  email: string;
  password: string;
};

export const registerUser = async (data: RegisterData) => {
  return await fetcher(backendEndpoints.REGISTER_URL, {
    body: {
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      password: data.password,
    },
    method: 'POST',
    parser: (data) => RegistrationResponseSchema.parse(data),
  });
};

export const verifyUser = async (email: string, otp: string) => {
  return await fetcher(backendEndpoints.VERIFY_USER_URL, {
    body: {
      email,
      otp,
    },
    method: 'POST',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const resendVerificationOtpMail = async (email: string) => {
  return await fetcher(backendEndpoints.RESEND_VERIFICATION_URL, {
    body: {
      email,
    },
    method: 'POST',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const signInUser = async ({ email, password }: LoginData) => {
  const res = await fetcher(backendEndpoints.SIGN_IN_URL, {
    body: {
      email,
      password,
    },
    method: 'POST',
    parser: (data) => SignInSchema.parse(data),
    includeCredentials: true,
  });

  if (res.isSuccessful) {
    useAuthenticationStore.setState({ refreshFailed: false });
  }

  return res;
};

export const forgotPassword = async (email: string) => {
  return await fetcher(backendEndpoints.RESET_PASSWORD_INITIATE_URL, {
    body: {
      email,
    },
    method: 'POST',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const resetPasswort = async (
  newPassword: string,
  otp: string,
  email: string,
) => {
  return await fetcher(backendEndpoints.RESET_PASSWORD_FULLFILL_URL, {
    body: {
      otp,
      password: newPassword,
      email,
    },
    method: 'POST',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
  });
};

export const validateToken = async (skipValidCheck: boolean = false) => {
  const { accessToken, refreshFailed } = useAuthenticationStore.getState();

  const expiry = accessToken != '' ? jwtDecode(accessToken)?.exp ?? 0 : 0;

  if (refreshFailed) return;

  if (skipValidCheck || expiry < Date.now() / 1000) {
    try {
      const res = await fetcher(backendEndpoints.SIGN_IN_REFRESH_URL, {
        method: 'POST',
        parser: (data) => SignInSchema.parse(data),
        includeCredentials: true,
        body: {},
      });
      if (res.isSuccessful) {
        useAuthenticationStore.setState({
          accessToken: res.response.accessToken,
          user: res.response.user,
          userRole: res.response.user?.role,
        });
      } else {
        useAuthenticationStore.setState({
          userRole: 'free',
          user: null,
          accessToken: '',
          refreshFailed: true,
        });
      }
    } catch (e) {
      console.error('Error refreshing token', e);
    }
  }
};

export const signOut = async () => {
  const response = await fetcher(backendEndpoints.SIGN_OUT_URL, {
    method: 'POST',
    parser: (data) => GeneralBackendResponseObjectSchema.parse(data),
    includeCredentials: true,
    body: {},
  });

  if (response.isSuccessful) {
    useAuthenticationStore.setState({
      user: null,
      accessToken: '',
      userRole: 'free',
    });
    useFavoriteStore.setState({
      favorites: [],
      favoriteFolders: [],
    });
    useProfileStore.setState({ modalType: 'profile', isModalOpen: false });
  }

  return response;
};
