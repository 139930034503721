import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';

export const PositionSection = () => {
  const { t } = useTranslation();

  return (
    <Stack w='full' px='4' pb='4'>
      <SectionTitle title={t('editor.position.title')} icon='position' />
      <EditorNumberInput
        text='X'
        initialValue={0}
        onChange={() => null}
        minValue={1}
      />
      <EditorNumberInput
        text='Y'
        initialValue={0}
        onChange={() => null}
        minValue={1}
      />
    </Stack>
  );
};
