import {
  Button,
  GridItem,
  Image,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { outlined } from '$/components/common/Accessibility';
import { For } from '$/components/common/Flow/For';
import { Icon } from '$/components/common/Icon';
import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useNonFreeAction } from '$/hooks/useNonFreeAction';
import {
  trackContentImpression,
  trackContentInteraction,
  trackEvent,
} from '$/logger';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { endpointFactory } from '$/services/endpoints';
import { Scene } from '$/services/usecases/scenes';
import { repeat } from '$/utils/arrayUtils';
import { indexToLetter } from '$/utils/numberUtils';

interface Props {
  scene?: Scene | null;
}

export const PerspectiveSection: FC<Props> = ({ scene }) => {
  const { t } = useTranslation();

  const activeComponentId = useInspirationEditorStore.useActiveComponentId();
  const activeViewIndex = useInspirationEditorStore.useActiveViewIndex();

  const resetZoom = useActionBarStore.useResetZoom();
  const setActiveSceneIndex =
    useInspirationEditorStore.useSetActiveSceneIndex();

  const { variant } = useInspirationEditorStore(
    useShallow((s) => ({ variant: s.getActiveSceneVariant() })),
  );

  const togglePerspectiveFullscreen =
    useInspirationEditorStore.useTogglePerspectiveFullscreen();

  const handleNonFreeAction = useNonFreeAction();

  const onChangePerspective = (index: number) => {
    trackContentInteraction(
      'ClickPerspective',
      'DisplayPerspectives',
      'Perspectives',
      { targetName: indexToLetter(index) },
    );

    setActiveSceneIndex(index);

    if (
      activeComponentId &&
      !variant
        .find((layer) => layer.id === activeComponentId)
        ?.views.includes(index.toString())
    ) {
      useInspirationEditorStore.setState({ activeComponentId: null });
    }

    resetZoom();
  };

  const toggleFullscreen = () => {
    trackEvent('Fullscreen', 'Click', 'Inspiration');
    handleNonFreeAction(() => {
      togglePerspectiveFullscreen();
      resetZoom();
    });
  };

  useEffect(() => {
    if (!scene) return;

    trackContentImpression('DisplayPerspectives', 'Perspectives', {
      targetCount: scene?.views.length ?? 0,
    });
  }, [scene]);

  return (
    <Stack gap='3'>
      <Text color='lighterText' fontWeight='bold'>
        {t('inspirationEditor.perspectives')}
      </Text>

      <SimpleGrid gap='1' w='full' columns={3}>
        <For
          each={scene?.views}
          fallback={() =>
            repeat(3).map((key) => (
              <GridItem key={key}>
                <Skeleton h='40px' borderRadius='4px' aspectRatio='3/2' />
              </GridItem>
            ))
          }
        >
          {(view, index) => (
            <GridItem
              key={view}
              zIndex={activeViewIndex === index ? '1' : '0'}
              borderRadius='4px'
              cursor='pointer'
              onClick={() => onChangePerspective(index)}
              {...outlined({ visible: activeViewIndex === index })}
            >
              <Image
                layerStyle='SceneImage'
                h='40px'
                borderRadius='4px'
                alt={scene!.id}
                aspectRatio='3/2'
                loading='lazy'
                src={endpointFactory.sceneImageUrl({ id: scene!.id, view })}
              />
            </GridItem>
          )}
        </For>
      </SimpleGrid>

      <Button
        h='8'
        mt='2'
        fontSize='xs'
        fontWeight='normal'
        leftIcon={<Icon icon='zoom_out_map' width='3' />}
        onClick={toggleFullscreen}
        variant='secondary'
      >
        {t('inspirationEditor.fullscreenMode')}
      </Button>
    </Stack>
  );
};
