import { createRoute } from '@tanstack/react-router';

import { HtmlContentPage } from '$/pages/HtmlContentPage';
import { RootRoute } from '$/routes/RootRoute';
import { legalEndpoints } from '$/services/endpoints';

export const IntegrityRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/integrity',
  component: () => (
    <HtmlContentPage contentEndpoint={legalEndpoints.INTEGRITY_URL()} />
  ),
});
